import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

export const Title = styled.h3`
  ${tw`font-semibold mb-4`};
`;

export const Image = styled.figure`
  ${tw`px-8 mx-auto w-full max-w-prose md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl overflow-hidden`};

  *:first-child {
    ${tw`rounded-lg`}
  }
`;

export const Links = styled.div`
  ${tw`w-full flex justify-between mt-10`};
`;

export const DraftBanner = styled.div`
  width: 100%;
  z-index: 100;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(p) => p.theme.colors.primaryColor};

  padding: 1rem 3rem;

  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
`;
