import React from 'react';

import { StyledProps } from './styles';

import * as Styled from './styles';
import { Tag } from '../Tag';
import { Link } from 'gatsby';

interface Props extends StyledProps {
  title: string;
  subtitle?: string;
  tags?: string[];
  description?: string;
  collection: string;
}

const ArticleTitle: React.FC<Props> = (props) => {
  const { subtitle, title, center, tags, description, collection } = props;
  return (
    <Styled.Wrapper>
      {subtitle && <Styled.PreTitle center={center}>{subtitle}</Styled.PreTitle>}

      <Styled.MainTitle center={center}>{title}</Styled.MainTitle>

      {tags && (
        <Styled.SubTitle center={center}>
          {tags.map((tag) => (
            <Link
              to={`/${collection}/tags/${tag.replace(/\s+/g, '-').toLowerCase()}`}
              key={`post-${title}-tag-${tag}`}>
              <Tag>{tag}</Tag>
            </Link>
          ))}
        </Styled.SubTitle>
      )}

      {description && <Styled.Description center={center}>{description}</Styled.Description>}
    </Styled.Wrapper>
  );
};

export default ArticleTitle;
