import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import Img from 'gatsby-image';

import * as Styled from './styles';
import Article from 'components/ui/Article';
import ArticleTitle from 'components/ui/ArticleTitle';
import { ImageSharpFluid } from 'helpers/definitions';

interface Post {
  html: React.ReactNode;
  excerpt: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
    date: string;
    draft: boolean;
    tags: string[];
    description?: string;
    socialShare: {
      childImageSharp: {
        resize: {
          src: string;
          width: number;
          height: number;
        };
      };
    };
    cover: {
      childImageSharp: {
        fluid: ImageSharpFluid;
      };
    };
  };
}

interface Props {
  data: {
    markdownRemark: Post;
  };
  pageContext: {
    slug: string;
    next: Post;
    previous: Post;
  };
}

const BlogPost: React.FC<Props> = ({ data, pageContext }) => {
  const post = data.markdownRemark;

  const { previous, next } = pageContext;

  const shareImage = post.frontmatter.socialShare
    ? post.frontmatter.socialShare.childImageSharp.resize
    : undefined;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt || 'No description'}
        keywords={post.frontmatter.tags}
        image={shareImage}
        pathname={pageContext.slug}
        article
      />
      {post.frontmatter.draft && (
        <Styled.DraftBanner>Heads up, this is a draft post!</Styled.DraftBanner>
      )}
      <Container section prose>
        <ArticleTitle
          collection={'blog'}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.date}
          tags={post.frontmatter.tags}
          description={post.frontmatter.description}
        />
      </Container>
      {post.frontmatter.cover && (
        <Styled.Image>
          <Img fluid={post.frontmatter.cover.childImageSharp.fluid} alt={post.frontmatter.title} />
        </Styled.Image>
      )}

      <Container section prose className="prose">
        <Article content={post.html} />
        <Styled.Links>
          <span>
            {previous && (
              <Link to={previous.fields.slug} rel="previous">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </span>
          <span>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </span>
        </Styled.Links>
      </Container>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }, published: { eq: true }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMM DD, YYYY")
        draft
        tags
        description
        cover {
          childImageSharp {
            fluid(maxWidth: 1280, maxHeight: 650, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        socialShare: cover {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
