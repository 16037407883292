import React from 'react';

interface Props {
  content: any;
}

const Article: React.FC<Props> = (props) => {
  return (
    <article
      className="prose"
      dangerouslySetInnerHTML={{
        __html: props.content,
      }}></article>
  );
};

export default Article;
