import styled from 'styled-components/macro';
import tw from 'tailwind.macro';

export interface StyledProps {
  center?: boolean;
}

export const Wrapper = styled.div`
  ${tw`flex flex-col w-full`};
`;

export const MainTitle = styled.h1<StyledProps>`
  ${tw`text-4xl font-bold mb-2`};
  ${(props) => props.center && tw`text-center`};
`;

export const PreTitle = styled.p<StyledProps>`
  ${tw`text-md`};
  ${(props) => props.center && tw`text-center`};
`;

export const SubTitle = styled.h2<StyledProps>`
  ${tw`text-md`};
  ${(props) => props.center && tw`text-center`};
`;

export const Description = styled.p<StyledProps>`
  ${tw`mt-6`};
  ${(props) => props.center && tw`text-center`};
`;
